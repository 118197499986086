<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">家长帮助</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h85"></div>  
    <div>
      <div class="no-data-bg">

        <div @click="close" class="close-btn">关闭家长帮助</div>
        <div class="no-data-tip">您可佩戴设备校准至适宜您的清晰度帮助孩子操作。</div>
      </div>
      <!-- <div>&nbsp;</div> -->
      <div class="tips">完成对孩子协助后，请关闭家长帮助模式</div>
      <div class="tips">孩子即可继续护眼训练啦！</div>
    </div>
  </div>  
</template>

<script>
import { List, PullRefresh, Empty, Icon } from 'vant';
import moment from 'moment';
import { forEach } from 'lodash';
import { helpOpen, helpClose } from '@/api/user.js'

export default {
  components: {[List.name]: List, [Empty.name]: Empty, [PullRefresh.name]: PullRefresh, [Icon.name]: Icon},
  data() {
    return {
      deviceSn: this.$route.query.deviceSn
    }
  },
  filters: {
    // 局部过滤器
    dateF: function(v) {
      if (!v) return '';
      return moment(v).format('yyyy-MM-DD HH:mm:ss')
    }
  },
  created() {
    helpOpen({deviceSn: this.deviceSn})
  },
  methods: {
    close () {
      helpClose({deviceSn: this.deviceSn}).then(() => {
        this.$toast('关闭成功')
      })
    }
  },
  beforeDestroy() {
    helpClose({deviceSn: this.deviceSn})
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  overflow: auto;
  .no-data-bg {
    position: relative;
    width: 5.92rem;
    height: 5.79rem;
    margin: 2rem auto 0;
    background: url('../../accesst/helper/close.png') no-repeat;
    background-size:5.92rem 5.79rem;
    .close-btn {
      width: 2rem;
      height: 2rem;
      // border: 1px solid red;
      font-weight: 600;
      font-size: .31rem;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      line-height: 2rem;
      left: 1.85rem;
      top: 1.7rem;
    }
    
    .no-data-tip {
      margin: 0 auto;
      padding-top: 4.83rem;
      font-family: PingFangSC, PingFang SC;
      width: 4.52rem;
      line-height: .42rem;
      font-weight: 400;
      font-size: .27rem;
      color: #999999;
      text-align: center;
    }
  }
  .tips {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .27rem;
    color: #999999;
    line-height: .42rem;
    text-align: center;
  }
  .h85 {
    height: .85rem;
  }
  
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: auto;
    }
  }
  .fixedStyle {
    position: fixed;
    top: .85rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
</style>  