import request from '@/utils/request'
//  获取用户列表
export function getUserList(params) {
  return request({
    url: '/app-api/vision/sysuser-sub-user/listAll',
    method: 'get',
    params
  })
}
// 同步用户 /app-api/vision/sysuser-sub-user/syncSubUser
export function syncSubUser(params) {
  return request({
    url: '/app-api/vision/sysuser-sub-user/syncSubUser',
    method: 'get',
    params
  })
}
// /app-api/vision/sysuser-sub-user/create
export function creteUser(data) {
  return request({
    url: '/app-api/vision/sysuser-sub-user/create',
    method: 'post',
    data: {...data, createType: 1}
  })
}

export function updateUser(data) {
  return request({
    url: '/app-api/vision/sysuser-sub-user/update',
    method: 'put',
    data
  })
}

export function delUser(id) {
  return request({
    url: `/app-api/vision/sysuser-sub-user/delete?id=${id}`,
    method: 'delete',    
  })
}

// /app-api/eye/parent-help/page
export function helpList(params) {
  return request({
    url: '/app-api/eye/parent-help/page',
    method: 'get',
    params
  })
}

export function helpOpen(data) {
  return request({
    url: '/app-api/eye/parent-help/open?deviceSn=' + data.deviceSn,
    method: 'post',
    data,
  })
}

export function helpClose(data) {
  return request({
    url: '/app-api/eye/parent-help/close?deviceSn=' + data.deviceSn,
    method: 'post',
    data,
  })
}
